<script type="text/javascript" src="~/Scripts/currency.js"></script>
<script type="text/javascript" src="~/Scripts/formHelper.js"></script>
<script src="https://cdn.jsdelivr.net/npm/gijgo@1.9.6/js/gijgo.min.js" type="text/javascript"></script>
<link href="https://cdn.jsdelivr.net/npm/gijgo@1.9.6/css/gijgo.min.css" rel="stylesheet" type="text/css" />

<form id="AddForm" [formGroup]="transactionForm">
  <div class="container">
    <div class="row">
      <div class="col-xl-3"><label for="TranDate">Date:</label></div>
      <div class="col-xl-9">
        <input title="TransDt99" matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" formControlName="TransDt">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>      
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3"><label for="Description">Description:</label></div>
      <div class="col-xl-9"><input type="text" title="Description" formControlName="Description" style="width: 100%" /></div>
    </div>

    <div class="row">
      <div class="col-xl-3">Transaction&nbsp;type: </div>
      <div class="col-xl-9">
        <mat-button-toggle-group name="TranType" aria-label="Transaction Type" (change)="changeTransactionType($event)" [value]="TranType.value">
          <mat-button-toggle value="P">Payment</mat-button-toggle> 
          <mat-button-toggle value="D">Deposit</mat-button-toggle> 
          <!--<label class="btn btn-outline-primary" for="TranTypeTransfer">
            <input type="radio" name="TranType" formControlName="TranType" id="TranTypeTransfer" value="T" (click)="changeTransactionType($event)" autocomplete="off" />Transfer
          </label> -->
        </mat-button-toggle-group>       
      </div>
    </div>

  <div formArrayName="Splits" style="border: 1px solid; margin-top: 1em; padding: 0.5em 0.5em 0.5em 0.5em; "
    *ngFor="let item of transactionForm.get('Splits')['controls']; let i = index;">
    <div [formGroupName]="i">
        <div><label for="CategoryList_{{index}}__CategoryId">Category</label></div>
        <div><select data-val="true" data-val-number="The field Category must be a number." formControlName="CategoryId">
          <option value="" selected>== Please select ==</option>
          <option *ngFor="let cat of categoryList" [value]="cat.categoryId">{{ cat.name }}</option>
        </select></div>
    
        <div style="margin-top: 1em"><label for="CategoryList_0__CategoryAmount">Amount</label></div>
        <div><input step="0.01" type="text" inputmode="decimal" class="cssCategoryAmount" data-val="true" data-val-number="The field Amount must be a number." 
          formControlName="Amount" value=""> <!-- TODO: Remove if inputmode works (keydown)="onKeyDownAmount($event)" -->
          <div style="float: right; padding-top: 0.75em;"><a (click)="showMoreSplits()" tabindex="0">Show More</a></div>
        </div>
        </div>
  </div>

  <div class="row">
    <div class="col-xl-3"><label for="Memo">Memo</label>:</div>
    <div class="col-xl-9"><input type="text" id="Memo" formControlName="Memo"></div>
  </div>

  <div class="row">
    <div class="col-xl-3"><label for="CheckNumber">Check Number</label>:</div>
    <div class="col-xl-9"><input type="text" id="CheckNumber" formControlName="CheckNumber" /></div>
  </div>
</div>


<!-- VALIDATION SUMMARY -->
<div
  *ngIf="showNotValid(this.TransDt)"
  class="alert alert-danger"
>
  <div *ngIf="hasError(this.TransDt, 'required')">Transaction Date is required.</div>
</div>

<div
  *ngIf="showNotValid(this.Description)"
  class="alert alert-danger"
>
  <div *ngIf="hasError(this.Description, 'required')">Description is required.</div>
</div>

<div *ngFor="let thisItem of transactionForm.get('Splits')['controls']; let i = index">
  <div
    *ngIf="showNotValid(thisItem.controls.CategoryId)"
    class="alert alert-danger"
  >
    <div *ngIf="hasError(thisItem.controls.CategoryId, 'required')">Category {{i}} is required.</div>
  </div>

  <div
    *ngIf="showNotValid(thisItem.controls.Amount)"
    class="alert alert-danger"
  >
    <div *ngIf="hasError(thisItem.controls.Amount, 'required')">Amount {{i}} is required.</div>
    <div *ngIf="hasError(thisItem.controls.Amount, 'pattern')">Amount {{i}} is not a valid amount.</div>
  </div>

</div>

<div
  *ngIf="showNotValid(this.CheckNumber)"
  class="alert alert-danger"
>
  <div *ngIf="hasError(this.CheckNumber, 'pattern')">Check number can only be a number.</div>
</div>

<div><button class="btn btn-primary" id="btnAdd" (click)="addTransaction()" [disabled]="transactionForm.valid == false">Add</button>   <!---->
  <button class="btn btn-secondary" id="btnCancel" (click)="cancelTransaction()">Cancel</button></div>
</form>

<script>
        $(document).ready(function () {
            fnFormSetup("AddForm", "btnSubmit", "");

            fnSetupCurrencyKeyboard("#CategoryList_0__CategoryAmount");
            fnSetupCurrencyKeyboard("#CategoryList_1__CategoryAmount");
            fnSetupCurrencyKeyboard("#CategoryList_2__CategoryAmount");
            fnSetupCurrencyKeyboard("#CategoryList_3__CategoryAmount");
            fnSetupCurrencyKeyboard("#CategoryList_4__CategoryAmount");

        });//ready
</script>

