import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './AuthService';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthTokenInterceptorService implements HttpInterceptor {
    constructor(public auth: AuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
        if(environment.debug) 
        {
            console.log("entering AuthTokenInterceptorService");
        }

        const token : string = this.auth.getAccessToken();
        if (token != null && token !== "") {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` } 
            });
        }

        return next.handle(request);
    }
}