import { Component } from '@angular/core';
import { AuthService } from './services/AuthService';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private authService : AuthService, private activeRoute: ActivatedRoute) {

  }

  ngOnInit() {
    let codeExists : boolean = false;

    if (document.location.href.indexOf("code=") >= 0 && document.location.href.indexOf("scope=") >= 0)
      codeExists = true;

    if (this.authService.hasValidAccessToken() == false && codeExists) {
      this.authService.checkForAuthCodeResponse();
    }
  }

}
