<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">logo here</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active"><a class="nav-link" href="#">Home</a></li>
      <li class="nav-item"><a class="nav-link" [href]="dashboardUrl">Dashboard</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/member/bankimport" routerLinkActive="active">Bank Import</a></li>          
      <li class="nav-item"><a class="nav-link" (click)="login()">Login</a></li>
      <li class="nav-item"><a class="nav-link" (click)="logout()" *ngIf="authService.hasValidAccessToken()">Logout</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/member/test" routerLinkActive="active" *ngIf="authService.hasValidAccessToken()">Testing</a></li>
    </ul>
  </div>
</nav>