import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/AuthService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public dashboardUrl : string;
  public loggedIn : boolean = false;

  constructor(public authService : AuthService) { }

  ngOnInit() {
    this.dashboardUrl = environment.legacyMoneyWebUrl;
    this.loggedIn = this.authService.hasValidAccessToken();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }
}
