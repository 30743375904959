
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MoneyApiService } from '../../services/money-api.service';
import { Account } from '../../models/Account';

import { UntypedFormGroup, UntypedFormControl  } from "@angular/forms";
import { BankImport } from '../../models/BankImport';
import { ITransaction } from '../../models/ITransaction';

import { ActivatedRoute } from '@angular/router';

import { TransactionDisplay } from '../../models/TransactionDisplay';
import { FileToUpload } from '../../models/FileToUpload';
import { BankImportAccountStatus } from '../../models/BankImportAccountStatus';

export enum BankImportComponentView {
  SelectAccount = 1,
  UploadFile = 2,
  MatchTransactions = 3
}

@Component({
  selector: 'app-bankimport',
  templateUrl: './bankimport.component.html',
  styleUrls: ['./bankimport.component.css'] 
})
export class BankimportComponent implements OnInit {
  private accountList : BankImportAccountStatus[];
  private bankTransactionList : BankImport[];
  private transactionsForReconciliationList : TransactionDisplay[];
  private currentBankTranIdx : number = 0; //TODO: what if no transactions are imported?
  public BankImportComponentView = BankImportComponentView;

  private debug : boolean = false;

  public activePanel : BankImportComponentView; //= BankImportComponentView.SelectAccount;
  public showAddTransaction:boolean = false;

  private selectFileForm : UntypedFormGroup;
  private uploadedFile : FileToUpload = null;

  private instanceId : number = 1;
  private selectedAccountId : number = null;
  private selectedAccountIdx : number = null;
  private countActioned : number = 0;
  private matchTypeTabIndex : number = 0;

  //defaultBankImport is used as a single instance placeholder for binding before an actual BankImport is available
  private defaultBankImport : BankImport = new BankImport();

  constructor(private moneyService : MoneyApiService, private route: ActivatedRoute) { 
    this.activePanel = BankImportComponentView.SelectAccount;
  }

  ngOnInit() {
    this.route.queryParams.pipe(
    filter(params => params.panel))
    .subscribe(params => {
      this.activePanel = params.panel;
    });

    this.loadSelectAccountPanel();
    
    this.selectFileForm = new UntypedFormGroup({
      'bankfile': new UntypedFormControl('')
    });
  }

  loadSelectAccountPanel() {
    this.moneyService.getBankImportAccountStatus().subscribe(data => {
      this.accountList = data;
      this.activePanel = BankImportComponentView.SelectAccount;
    });
  }

  onSelectAccount(idx : number) {
    this.selectedAccountIdx = idx;
    this.selectedAccountId = this.accountList[idx].accountId;

    if (this.debug)
    alert(`you selected ${this.selectedAccountId}`);

    this.activePanel = BankImportComponentView.UploadFile;
  }

  onFileChange(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];

      reader.readAsText(file);
      reader.onload = () => {
          this.uploadedFile = new FileToUpload();
          this.uploadedFile.fileName = file.name;
          this.uploadedFile.fileSize = file.size;
          this.uploadedFile.fileType = file.type;
          this.uploadedFile.lastModifiedDate = file.lastModifiedDate;
          this.uploadedFile.lastModifiedTime = file.lastModifiedTime;
          this.uploadedFile.fileContent = reader.result as string;
        };
    }
  }//end onFileChange

  onSubmitUploadFileClick() {
      if (this.uploadedFile && this.uploadedFile.fileContent && this.uploadedFile.fileContent.length > 0)
      {
        if (this.debug)
          alert('file contents: ' + this.uploadedFile.fileContent.substring(0, 100));
        this.moneyService.uploadBankImportFile(this.instanceId, this.selectedAccountId, this.uploadedFile)
          .subscribe(() => this.getBankImportRowsAndGotoMatching());
        //TODO handle result and add error handling        
      }
  }

  nextTransaction() {
    if (this.currentBankTranIdx + 1 < this.bankTransactionList.length)
    {
      this.currentBankTranIdx++;
      this.getMatchingSystemTransactions();
    }
  }

  previousTransaction() {
    if (this.currentBankTranIdx - 1 >= 0)
    {
      this.currentBankTranIdx--;
      this.getMatchingSystemTransactions();
    }
  }

  currentTransaction() : BankImport {
    let thisTran : BankImport = this.defaultBankImport;

    if (this.bankTransactionList && this.bankTransactionList.length > 0 && this.currentBankTranIdx - 1 <= this.bankTransactionList.length) {
      thisTran = this.bankTransactionList[this.currentBankTranIdx];
    }
    return thisTran;
  }

  setMatchingTransaction(transaction : TransactionDisplay) {
    const rowId = this.bankTransactionList[this.currentBankTranIdx].rowId;

    this.moneyService.markAsReconciled(this.instanceId, this.selectedAccountId, rowId, transaction.transId)
      .subscribe(() => {
        this.bankTransactionList[this.currentBankTranIdx].matchingTransId = transaction.transId;
        this.bankTransactionList[this.currentBankTranIdx].MatchingTransaction = transaction;
        this.countActioned++;
        this.matchTypeTabIndex = 0;
        this.nextTransaction();    
        //How to handle errors if it doesn't complete successfully
      }
    );
  }

  undoMatchingTransaction(transaction : TransactionDisplay) {
    const rowId = this.bankTransactionList[this.currentBankTranIdx].rowId;

    this.moneyService.markAsReconciled(this.instanceId, this.selectedAccountId, rowId, null)
      .subscribe(() => {
        this.bankTransactionList[this.currentBankTranIdx].matchingTransId = null;
        this.bankTransactionList[this.currentBankTranIdx].MatchingTransaction = null;
        this.countActioned--;

        //How to handle errors if it doesn't complete successfully

        //Refresh transactions so the "matched" indicator clears
        this.getMatchingSystemTransactions();
      }
    );

  }

   getMatchingSystemTransactions() {
      let bankTran : BankImport = this.currentTransaction();

      if (bankTran != null) {
        this.moneyService.getTransactionsForReconciliation(this.selectedAccountId, bankTran.amount, bankTran.transDt, this.instanceId)
          .subscribe((data: TransactionDisplay[]) => {
            this.transactionsForReconciliationList = data;
        });
      }
   }

   addAsNewClick(transaction : ITransaction) {
     this.showAddTransaction = true;
   }

   onAddNewTransaction(transaction : TransactionDisplay) {
    this.setMatchingTransaction(transaction);
    this.hideNewTransactionForm();
   }

   hideNewTransactionForm() {
     this.showAddTransaction = false;
   }

   finishImport() {
    this.moneyService.deleteBankImportsForAccount(this.instanceId, this.selectedAccountId)
      .subscribe(() => {
        this.loadSelectAccountPanel();
      });
   }

   continueLater() {
    this.loadSelectAccountPanel();
   }

  getBankImportRowsAndGotoMatching() {
    this.moneyService.getBankTransactionsForImport(this.instanceId, this.selectedAccountId).subscribe((data: BankImport[]) => {
      this.bankTransactionList = data;
      this.currentBankTranIdx = 0;
      this.getMatchingSystemTransactions();
      this.activePanel = BankImportComponentView.MatchTransactions;
    });
  }

  testButton() {
    this.matchTypeTabIndex = 0;
  }
}

/*
MVP:
* If account already has progress, prompt whether to continue or restart with a new file
* Handle if there are 0 bank transactions to match after the file is uploaded

Post MVP:
* Ability to add new transaction to match it
*/