import { Component } from '@angular/core';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { LoadingSpinnerControlServiceService } from '../../services/loading-spinner-control-service.service';
import { delay } from 'rxjs/operators';
import { TransactionDisplay } from '../../models/TransactionDisplay';

@Component({
  selector: 'app-feature-test',
  templateUrl: './feature-test.component.html',
  styleUrls: ['./feature-test.component.css']
})
export class FeatureTestComponent {

  constructor(private loadingSpinnerControlService : LoadingSpinnerControlServiceService) {

  }

  showSpinner() {
    this.loadingSpinnerControlService.show();
    
    this.sleep(5000).then(() => {
      this.loadingSpinnerControlService.hide();
    })
  }

  sleep = (ms) => new Promise(r => setTimeout(r, ms));

  processAddTransaction(transaction : TransactionDisplay) {
    console.log(transaction);
    alert("Add transaction: " + transaction.description);
  }

  processCancelTransaction() {
    alert("Cancel transaction");
  }
}
