import { Component } from '@angular/core';
import { LoadingSpinnerControlServiceService } from '../../services/loading-spinner-control-service.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent {

  constructor(public controlService : LoadingSpinnerControlServiceService) {

  }
}
