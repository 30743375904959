import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { LoadingSpinnerControlServiceService } from './loading-spinner-control-service.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    DEBUG : boolean = true;
    POSTLOGIN_REDIRECT_KEY : string = "money2_postlogin_redirect";

    authconfig = {
        issuer: environment.identityServerUrl,
        clientId: 'money2.mfinity.app',
        scope: 'openid profile email privacy money',
        responseType: 'code',
        redirectUri: window.location.origin + '/',
        showDebugInformation: this.DEBUG
    }

    constructor(private oauthService: OAuthService, 
        private router : Router, 
        private loadingSpinnerControlService : LoadingSpinnerControlServiceService) 
    {
        this.oauthService.configure(this.authconfig);

        this.oauthService.oidc = true; // ID_Token
        this.oauthService.setStorage(sessionStorage);
        this.enableEventLogging();
    }

    enableEventLogging() {
        if (this.DEBUG)
            this.oauthService.events.subscribe(e => (e instanceof OAuthErrorEvent) ? console.error(e) : console.warn(e));
    }

    public login() {
        this.loginAndRedirect('/');
    }

    public loginAndRedirect(redirectPath: string) {
        sessionStorage.setItem(this.POSTLOGIN_REDIRECT_KEY, redirectPath);
        if (this.oauthService.discoveryDocumentLoaded == false)
        {
            this.oauthService.loadDiscoveryDocument().then(() => {
                this.oauthService.initCodeFlow();
            });
        }
        this.oauthService.initCodeFlow();
    }
    
    public logout() {
        this.oauthService.logOut();
    }

    public checkForAuthCodeResponse() {
        this.loadingSpinnerControlService.show();
        this.oauthService.loadDiscoveryDocument().then(() => {
            this.oauthService.tryLoginCodeFlow().then(() => {
                if(this.hasValidAccessToken()) {
                    const redirectPath : string = sessionStorage.getItem(this.POSTLOGIN_REDIRECT_KEY);
                    sessionStorage.removeItem(this.POSTLOGIN_REDIRECT_KEY);
                    this.router.navigate([redirectPath]);
                }
                this.loadingSpinnerControlService.hide();
            });
        });    
    }

    public hasValidAccessToken() { return this.oauthService.hasValidAccessToken(); }

    public hasIdToken() {
        return this.oauthService.hasValidIdToken();
    }

    public getAccessToken() {
        return this.oauthService.getAccessToken();
    }
    // Other methods related to authentication, token refresh, etc.
}
