import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservedValueOf } from 'rxjs';
import { environment } from '../../environments/environment';
import { iMoneyApiService } from './money-api.interface';
import { MockMoneyApiService } from './mock-money-api.service';
import { TransactionDisplay } from '../models/TransactionDisplay';
import { BankImportAccountStatus } from '../models/BankImportAccountStatus';
import { FileToUpload } from '../models/FileToUpload';
import { BankImport } from '../models/BankImport';
import { Category } from '../models/Category';
import { TransactionSaveModel } from '../models/TransactionSaveModel';

@Injectable()
export class MoneyApiService implements iMoneyApiService {
  private mockservice : MockMoneyApiService;

  //TODO: Instance ID?
  constructor(private http: HttpClient) { 
    this.mockservice = new MockMoneyApiService();
  }

  // getFriendsInChatList() : Observable<FriendsInChatList> {
  //   return this.http.get<FriendsInChatList>(environment.twitchBotApiUrl + "api/users/friends");
  // }

  getAccountList() {
    return this.mockservice.getAccountList();
  }

  //Bank Import

  getBankImportAccountStatus() : Observable<BankImportAccountStatus[]> {
    return this.http.get<BankImportAccountStatus[]>(environment.moneyApiUrl + "api/1/BankImport/status");
  }

  uploadBankImportFile(instanceId : number, accountId : number, file : FileToUpload) : Observable<Object> {
    return this.http.post(environment.moneyApiUrl + `api/${instanceId}/bankimport/ofx/${accountId}`, file);
      //.pipe()
  }

  getBankTransactionsForImport(instanceId : number, accountId : number) : Observable<BankImport[]> {    
    return this.http.get<BankImport[]>(environment.moneyApiUrl + `api/${instanceId}/bankimport/account/${accountId}`)
  }

  getTransactionsForReconciliation(accountId : number, amount : number, rootDate : Date, instanceId : number) : Observable<TransactionDisplay[]>{
    const lookbackDays : number = 29;
    const lookforwardDays : number = 16;
    return this.http.get<TransactionDisplay[]>(
      environment.moneyApiUrl + `api/transaction/search/account/${accountId}/amount/${amount}/date/${rootDate}/before/${lookbackDays}/after/${lookforwardDays}/instance/${instanceId}`
    );
  }

  getCategoryList(instanceId : number) : Observable<Category[]> {
    let route = `api/${instanceId}/category`;
    return this.http.get<Category[]>(environment.moneyApiUrl + route);
  }

  addTransaction(transaction : TransactionSaveModel) {
    let route = "api/transaction";
    return this.http.post<TransactionSaveModel>(environment.moneyApiUrl + route, transaction);
  }

  markAsReconciled(instanceId : number, accountId : number, rowId: number, matchingTranId: number) : Observable<Object> {
    let route = `api/${instanceId}/bankimport/account/${accountId}/row/${rowId}/reconciled`;
    if (matchingTranId) 
    {
        route += `/${matchingTranId}`
    }

    return this.http.put(environment.moneyApiUrl + route, "");
  }

  deleteBankImportsForAccount(instanceId : number, accountId : number) : Observable<Object> {
    return this.http.delete(environment.moneyApiUrl + `api/${instanceId}/bankimport/account/${accountId}`);
  }
}
