import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BankimportComponent } from './bankimport/bankimport.component';
import { AuthGuard } from './AuthGuard';
import { FeatureTestComponent } from './feature-test/feature-test.component';

const routes: Routes = [
  { path: 'member',  
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [ AuthGuard ] } ,
      { path: 'bankimport', component: BankimportComponent, canActivate: [ AuthGuard ] },
      { path: 'test', component: FeatureTestComponent, canActivate: [ AuthGuard ]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }

