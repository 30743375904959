import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { MemberModule } from './member/member.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthTokenInterceptorService } from './services/AuthTokenInterceptorService';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const appRoutes: Routes = [
  { path: '',               component: HomeComponent,
    children: [
        {path: '',          component: HeaderComponent, outlet: 'header' }
    ]
  },
  { path: 'login',          component: LoginComponent },
];

@NgModule({ declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        LoginComponent,
        HomeComponent,
        LoadingSpinnerComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        MemberModule,
        RouterModule.forRoot(appRoutes, { enableTracing: false }),
        BrowserAnimationsModule,
        OAuthModule.forRoot(),
        MatProgressSpinnerModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptorService, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
