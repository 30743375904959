import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITransaction } from '../../models/ITransaction'
import { StringHelpersService } from '../../services/string-helpers.service';

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.css'],
  providers: [ StringHelpersService ]
})
export class TransactionCardComponent implements OnInit {
  @Input() public transaction : ITransaction;
  @Input() public showMatchButton : boolean = false;
  @Input() public showAddButton : boolean = false;
  @Input() public showUndoButton : boolean = false;
  @Input() public isMatched : boolean = false;

  @Output() onMatchingTransaction = new EventEmitter();
  @Output() onAddAsNewTransaction = new EventEmitter();
  @Output() onUndoMatch = new EventEmitter();

  constructor(public stringHelper : StringHelpersService) { }

  ngOnInit() {
   }

  useThisOneClick() {
    this.onMatchingTransaction.emit(this.transaction);
  }

  addAsNewClick() {
    this.onAddAsNewTransaction.emit(this.transaction);
  }

  onUndoMatchClick() {
    this.onUndoMatch.emit(this.transaction);
  }

  debug() {
    if (this.transaction == null)
      alert("null");
    else
      alert(this.transaction.description);
  }
}
