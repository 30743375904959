import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerControlServiceService {

  shouldDisplay : boolean;

  constructor() { }

  show() {
    this.shouldDisplay = true;
  }

  hide() {
    this.shouldDisplay = false;
  }
}
