export class Account {
    constructor() {

    }

    public AccountId: number;
    public InstanceId: number;   
    public Name: string;
    public ArchiveDt: Date;
    public VendorAccountId: string;
    public AccountTypeId: number;
    public BankName: string;
    public BudgetRequiredStartBalance: number;
    public LastImportDate: Date;
    public IsImportInProgress: boolean = false;
}

