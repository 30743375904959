import { Account } from "./Account";
import { ITransaction } from "./ITransaction";
import { TransactionCategory } from "./TransactionCategory";

export class TransactionDisplay implements ITransaction {
    constructor() {

    }

    public transId: number;
    public accountId: number;
    public accountName: string;
    public transDt: Date;
    public description: string;
    public memo: string;
    public amount: number;
    public vendorTransId: string;
    public reconciled: number;
    public checkNum: number;
    public isTransfer: boolean;
    public parentTransId: number;
    public account: Account;
    public splits: Array<TransactionCategory> = [];    
}