import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { MemberRoutingModule } from './member-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BankimportComponent } from './bankimport/bankimport.component';
import { TransactionCardComponent } from './transaction-card/transaction-card.component';
import { TransactionEntryComponent } from './transaction-entry/transaction-entry.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MoneyApiService } from '../services/money-api.service';
import { FeatureTestComponent } from './feature-test/feature-test.component';

@NgModule({
  imports: [
    CommonModule,
    MemberRoutingModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,    
    MatButtonToggleModule
  ],
  declarations: [DashboardComponent, BankimportComponent, TransactionCardComponent, TransactionEntryComponent, FeatureTestComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [ 
    MoneyApiService
  ]
})
export class MemberModule { }
