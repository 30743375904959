import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/AuthService';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.hasValidAccessToken()) {
        return true;
    }
    else if (this.authService.hasIdToken()) {
      alert("no valid access token, but there is a valid Id token");
    }
    else {
      
        // Redirect to login page or show an error message
        const accessedResource : string = this.getResolvedUrl(next);
        this.authService.loginAndRedirect(accessedResource);
        return false;
    }
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
  }
}
