<div class="card transaction-card">
  <div class="card-body">
    <div style="float: right;">
      <div *ngIf="showMatchButton == true"><button class="btn btn-primary" (click)="useThisOneClick()">Use this one</button></div>
      <!-- div *ngIf="showAddButton == true"><button class="btn btn-primary" (click)="addAsNewClick()">Add as new</button></div -->
      <div *ngIf="showUndoButton == true"><button class="btn btn-primary" (click)="onUndoMatchClick()">Undo</button></div>
      <div *ngIf="isMatched == true" style="margin-top: 0.5em"><mark>Matched</mark></div>
    </div>
    <div class="card-title">{{transaction.description}}</div>
    <div class="card-text">{{transaction.transDt | date:"shortDate"}}<span style="margin-left: 3em;">{{transaction.amount | currency}}</span></div>
    <div class="card-text" *ngIf="stringHelper.hasValue(transaction.memo)"><i>Memo:</i> {{ transaction.memo }}</div>
    <div class="card-text" *ngIf="stringHelper.hasValue(transaction.checkNum)"><i>Check Number:</i> {{transaction.checkNum}}</div>
  </div>
</div>
  