import { Injectable } from '@angular/core';
import { Account } from '../models/Account'
import { BankImport } from '../models/BankImport'
import { iMoneyApiService } from './money-api.interface';
import { TransactionDisplay } from '../models/TransactionDisplay';
import { Category } from '../models/Category';
import { Observable, of } from 'rxjs';
import { BankImportAccountStatus } from '../models/BankImportAccountStatus';
import { TransactionSaveModel } from '../models/TransactionSaveModel';

@Injectable()
export class MockMoneyApiService implements iMoneyApiService {

  constructor() { }

  getAccountList() {
    let mocklist: Array<Account> = [];
    let thisacct : Account;
    
    thisacct = new Account();
    thisacct.AccountId = 1;
    thisacct.InstanceId = 1;
    thisacct.Name = "Bills";
    thisacct.LastImportDate = new Date("6/2/2018");
    thisacct.IsImportInProgress = true;
    mocklist.push(thisacct);

    thisacct = new Account();
    thisacct.AccountId = 2;
    thisacct.InstanceId = 1;
    thisacct.Name = "Savings";
    thisacct.LastImportDate = new Date("4/3/2018");
    thisacct.IsImportInProgress = false;
    mocklist.push(thisacct);

    thisacct = new Account();
    thisacct.AccountId = 3;
    thisacct.InstanceId = 1;
    thisacct.Name = "Ally Savings";
    thisacct.LastImportDate = null;
    thisacct.IsImportInProgress = false;
    mocklist.push(thisacct);

    return of(mocklist);
  }

  getBankTransactionsForImport(instanceId : number, accountId : number) {
    let mocklist: Array<BankImport> = [];
    let thisbanktran: BankImport;

    thisbanktran = new BankImport();
    thisbanktran.transId = 211;
    thisbanktran.accountId = 13;
    thisbanktran.vendorTransId = "1001";
    thisbanktran.transDt = new Date("3/1/2018");
    thisbanktran.description = "Speedway 308";
    thisbanktran.amount = -20.66;
    mocklist.push(thisbanktran);

    thisbanktran = new BankImport();
    thisbanktran.transId = 212;
    thisbanktran.accountId = 13;
    thisbanktran.vendorTransId = "1002";
    thisbanktran.transDt = new Date("3/5/2018");
    thisbanktran.description = "Best Buy";
    thisbanktran.amount = -187.23;
    thisbanktran.memo = "POS 5325 Albany NY"
    mocklist.push(thisbanktran);

    thisbanktran = new BankImport();
    thisbanktran.transId = 213;
    thisbanktran.accountId = 13;
    thisbanktran.vendorTransId = "1003";
    thisbanktran.transDt = new Date("3/11/2018");
    thisbanktran.description = "The Ayco Company, L.P.";
    thisbanktran.amount = 2016.87;
    thisbanktran.memo = "Direct Deposit"
    mocklist.push(thisbanktran);

    thisbanktran = new BankImport();
    thisbanktran.transId = 214;
    thisbanktran.accountId = 13;
    thisbanktran.vendorTransId = "1004";
    thisbanktran.transDt = new Date("3/14/2018");
    thisbanktran.description = "Check 765";
    thisbanktran.amount = -80.00;
    thisbanktran.checkNum = 765;
    mocklist.push(thisbanktran);

    return of(mocklist);
  }

  getTransactionsForReconciliation() {
    let mockTransactions : Array<TransactionDisplay> = [];
    let thistran : TransactionDisplay;

    thistran = new TransactionDisplay();
    thistran.transId = 101;
    thistran.description = "Speedway";
    thistran.accountId = 13;
    thistran.transDt = new Date("2/28/2018");
    thistran.amount = -20.66;
    mockTransactions.push(thistran);

    thistran = new TransactionDisplay();
    thistran.transId = 102;
    thistran.description = "Center for the Arts";
    thistran.accountId = 13;
    thistran.transDt = new Date("2/28/2018");
    thistran.amount = -45.00;
    thistran.memo = "admissions and movie package";
    mockTransactions.push(thistran);

    return mockTransactions;
  }  

  getCategoryList(instanceId : number) : Observable<Category[]>{
    let mockList : Array<Category> = [];
    let thisone : Category;

    thisone = new Category();
    thisone.categoryId = 1;
    thisone.name = "Groceries";
    thisone.archiveDt = null;
    thisone.instanceId = 1;
    mockList.push(thisone);

    thisone = new Category();
    thisone.categoryId = 2;
    thisone.name = "Gas";
    thisone.archiveDt = null;
    thisone.instanceId = 1;
    mockList.push(thisone);

    thisone = new Category();
    thisone.categoryId = 3;
    thisone.name = "Kids: Allowance";
    thisone.archiveDt = null;
    thisone.instanceId = 1;
    mockList.push(thisone);

    return of(mockList);
  }

  addTransaction(transaction : TransactionSaveModel) {
    console.log("Called mockMoney.addTransaction");
  }  

  markAsReconciled(instanceId: number, accountId: number, rowId: number, matchingTranId: number): Observable<Object> {
    return of(1);
  }

  deleteBankImportsForAccount(instanceId : number, accountId : number) {
    return of(1);
  }  

  setMatchCodeIfPreviouslyMatched(instanceId : number, accountId : number) {
    return of(1);
  }

  getBankImportAccountStatus() : Observable<BankImportAccountStatus[]> {
    const statusList : BankImportAccountStatus[] = [];
    let thisone : BankImportAccountStatus;

    thisone = new BankImportAccountStatus();
    thisone.accountId = 1;
    thisone.accountName = "Some name";
    thisone.inProgress = false;

    statusList.push(thisone);

    return of(statusList);
  }
}

/*
  Remaining
  -- Modal for "Finish Import"
  -- After selecting account, test if there are already BankImport records. If so, give choice to either  continue or upload to restart
*/