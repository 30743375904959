import { Injectable } from '@angular/core';
import { AuthService } from './AuthService';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(private authService : AuthService) { }

  public getInstanceId() : number {
    return 1;
  }

}
