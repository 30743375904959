import {ITransaction} from './ITransaction';
import {TransactionDisplay} from './TransactionDisplay';

export class BankImport implements ITransaction {
    public accountId: number;
    public rowId: number;
    public vendorTransId: string;
    public transDt: Date;
    public transType: string;
    public description: string;
    public memo: string;
    public amount: number;
    public checkNum: number;
    public matchingTransId: number;
    public matchCode: number;
    public transId : number; //TODO: should this be a string and replace VendorTransId?

    public MatchingTransaction : TransactionDisplay;
}
