<!--The content below is only a placeholder and can be replaced.-->
<div class="container">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <app-header></app-header>

            <router-outlet name="header"></router-outlet>
            <router-outlet></router-outlet>
            <app-loading-spinner></app-loading-spinner>
            
            <app-footer></app-footer>                    
        </div>
    </div>
</div>
