<h1>Bank Import</h1>
<div id="accountsPanel" *ngIf="activePanel == BankImportComponentView.SelectAccount">
  <p>Importing transactions lets you keep your account in sync with your bank. It will let you match transactions you have 
      entered with ones from your bank, and show you any additional transactions at your bank that you did not enter here.
  </p>    
<h2>Accounts</h2>
<p>First, select the account below into which you want to import from your bank.</p>

<table class="table table-striped table-hover">
  <thead>
    <tr>
      <th>Name</th>
      <th>In Progress?</th>
    </tr>
  </thead>
  <tbody>
    <tr scope="row" *ngFor="let account of accountList; let i = index">
      <td><a (click)="onSelectAccount(i)">{{ account.accountName }}</a></td>
      <td>
        <span *ngIf="account.inProgress" class="label label-default">In progress (data through {{ account.lastImportDate | date:'M/d/yyyy' }})</span>
      </td>
    </tr>
  </tbody>
</table>
</div>

<div id="uploadFilePanel" *ngIf="activePanel == BankImportComponentView.UploadFile">
  <h2>Matching currently in progress</h2>

  <div *ngIf="accountList[selectedAccountIdx].inProgress" style="margin-bottom: 1em">
    <p>This account has a previous uploaded that has not been fully matched yet? Would you like to complete matching that previous upload first?</p>
    <div style="margin-top: 1em;"><button class="btn btn-primary" (click)="getBankImportRowsAndGotoMatching()">Yes, Complete Matching</button></div>
  </div>

  <h2>Upload bank file</h2>

  <div style="margin-bottom: 1em">
    <span *ngIf="accountList[selectedAccountIdx].inProgress">Alternatively, you can upload a new file to start over. Go</span>
    <span *ngIf="accountList[selectedAccountIdx].inProgress == false">Next, go</span> to your bank website and download transactions in either Quicken (QFX) or OFX format.
      Then, select the browse button below and select the file.
  </div>

  <form id="selectFile" [formGroup]="selectFileForm">
  <div><b><label for="bankfile">Transaction file from bank</label></b>:</div>
  <div><input type="file" id="bankfile" (change)="onFileChange($event)" formControlName="bankfile" /></div>
  <div style="margin-top: 1.0em"><button class="btn" [class.btn-primary]="selectFileForm.valid" [class.btn-secondary]="!selectFileForm.valid" id="submitUploadFile" (click)="onSubmitUploadFileClick()" [disabled]="!selectFileForm.valid">Upload File</button></div>
  </form>
</div>

<div *ngIf="activePanel == BankImportComponentView.MatchTransactions">
<h2>Match Transactions</h2>

<div *ngIf="bankTransactionList.length == 0">
  <div><i>All bank transactions have already been matched.</i></div>
  <div style="margin-top: 1em;"><button class="btn btn-primary" (click)="finishImport()">Finish Import</button></div>
</div>

<div *ngIf="bankTransactionList.length > 0">
  <p>For each bank transaction below, either select the mFinity Money transaction that matches it or select to add it as a new transaction.</p>

  <h4>From bank:</h4>
  <div class="container">
    <div class="row">
      <div class="transaction-card-cols col-sm-12 col-md-8 col-lg-6 col-xl-6">
          <app-transaction-card [transaction]="currentTransaction()" [showAddButton]="currentTransaction().matchingTransId == null"
            [isMatched]="currentTransaction().matchingTransId != null" (onAddAsNewTransaction)="addAsNewClick($event)"></app-transaction-card>
      </div>
    </div>
  </div>

  <div style="text-align: center; margin-top: 1em;">
    <div>{{ countActioned }} actioned</div>
    <div><a (click)="previousTransaction()">Previous</a> | {{ currentBankTranIdx + 1 }} of {{ bankTransactionList.length }} | <a (click)="nextTransaction()">Next</a></div>
  </div>

  <div *ngIf="currentTransaction().MatchingTransaction != null" style="margin-top: 1em;">
    <h4>Matched with:</h4>
    <div class="container">
      <div class="row">
        <div class="transaction-card-cols col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <app-transaction-card [transaction]="currentTransaction().MatchingTransaction" [showUndoButton]="true"
              (onUndoMatch)="undoMatchingTransaction($event)"></app-transaction-card>
        </div>
      </div>
    </div>      
  </div>
  
  <div *ngIf="currentTransaction().MatchingTransaction == null && !showAddTransaction" style="margin-top: 1em;">
    <h4>From mFinity Money:</h4>
    <mat-tab-group class="mat-mdc-tab-group mat-primary" [(selectedIndex)]="matchTypeTabIndex">
      <mat-tab label="Existing">
        
        <div class="container" style="margin-top: 1.0em">
          <div class="row">
            <div class="transaction-card-cols col-sm-12 col-md-8 col-lg-6 col-xl-6"> 
              <app-transaction-card *ngFor="let tran of transactionsForReconciliationList" [showMatchButton]="true" [transaction]="tran" 
                (onMatchingTransaction)="setMatchingTransaction($event)" [isMatched]="tran.reconciled != 0"></app-transaction-card>
              <div *ngIf="transactionsForReconciliationList != null && transactionsForReconciliationList.length == 0">None available to show</div>
            </div>
          </div>
        </div>
    
      </mat-tab>
      <mat-tab label="Add Transaction">
        
        <div style="margin-top: 1em;"> <!-- *ngIf="showAddTransaction" -->
          <h4>Add new transaction:</h4>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8">
                <app-transaction-entry [transaction]="currentTransaction()" (onAddNewTransaction)="onAddNewTransaction($event)" (onCancelNewTransaction)="hideNewTransactionForm()"></app-transaction-entry>          
              </div>
            </div>
          </div>
        </div>
      
      </mat-tab>
      <!-- mat-tab label="Add Transfer">Add Transfer</mat-tab -->
    </mat-tab-group>
  </div>
    
  <div style="text-align: center; margin-top: 1em;">
    <div style="margin-top: 1em;">
      <button class="btn btn-primary" (click)="finishImport()">Finish Import</button>&nbsp; 
      <button class="btn btn-secondary" (click)="continueLater()">Continue later</button>
    </div>
  </div>
</div>
