import { Injectable } from '@angular/core';

@Injectable()
export class StringHelpersService {

  constructor() { }

  hasValue(value : any) : Boolean {
    let hasValue : Boolean = false;
    if (value) {
      let valueString: string = value.toString();
      hasValue = (valueString && valueString.length > 0);  
    }
    return hasValue;
  }
}
